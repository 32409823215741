import React from 'react'
import SEO from '../../components/SEO'
import { CONDITION_SLUG, PLAN_ID, currentActiveQuizKey } from '../../constants/localStorage'
// Common Components
import { Link } from 'gatsby'
import { Condition } from 'goodpath-common'
import { getQuizNavigationState } from '../../components/common/DynamicQuizPlanCTA'
// User context
import { useUserContext } from '../../contexts/User/userContext'
import { useEmployerContext } from '../../contexts/Employer/employerProvider'
import useLocalStorage from '../../utils/useLocalStorage'
import { AuthPage } from '../../components/LayerComponents/UserIdentificationLayer'
// Images

// Heroes
import MentalHeroMobile from '../../images/conditions/mental-health/mental-health-hero-mobile.png'
import MentalHeroDesktop from '../../images/conditions/mental-health/mental-health-hero-desktop.png'

// Highlights
import appScreens from '../../images/conditions/mental-health/app-screens.png'
import assessmentScreen from '../../images/conditions/mental-health/assessment-screen.png'
import coachScreen from '../../images/conditions/mental-health/coach-screen.png'
import products from '../../images/conditions/mental-health/products.png'
import productsDesktop from '../../images/conditions/mental-health/products-desktop.png'

// Icons
import targetIcon from '../../images/conditions/mental-health/icons/target-icon.svg'
import brainIcon from '../../images/conditions/mental-health/icons/brain-body-icon.svg'
import batteryIcon from '../../images/conditions/mental-health/icons/low-battery-icon.svg'

// Animations
import CareAnimationDesktop from '../../images/conditions/mental-health/personalized-care-animation-desktop.gif'
import CareAnimationMobile from '../../images/conditions/mental-health/personalized-care-animation-mobile.gif'
import mentalHealthIntegrativeGifDesktop from '../../images/conditions/mental-health/integrative-animation-mh-desktop.gif'
import mentalHealthIntegrativeGifMobile from '../../images/conditions/mental-health/integrative-animation-mh-mobile.gif'

// Articles
import bloombergArticle from '../../images/conditions/mental-health/articles/bloomberg-news-article.jpg'
import ebnArticle from '../../images/conditions/mental-health/articles/ebn-news-article.jpg'
import timeArticle from '../../images/conditions/mental-health/articles/time-news-article.jpg'

// Geometry
import QuarterCircle from '../../../assets/geometry/quarter-circle.svg'
import Ring from '../../../assets/geometry/ring.svg'
import Circle from '../../../assets/geometry/circle.svg'
import Line from '../../../assets/geometry/line.svg'
import squiggle from '../../images/conditions/mental-health/squiggle.svg'

// Components
import PartnerImages from '../../components/Homepage/PartnerImages'

// Styles
const h2 =
  'sofia text-3xl sm:text-4xl md:text-5xl md:max-w-1/2 lg:max-w-4xl leading-tight text-gp-grey-100 text-center px-4 tracking-tighter mx-auto pb-1'
const h3 = 'text-2xl leading-tight text-gp-grey-100 text-center px-4'

const MentalHealth = ({ location }) => {
  const { user } = useUserContext()
  const [planId] = useLocalStorage(PLAN_ID)
  const [planCondition] = useLocalStorage(CONDITION_SLUG)
  const [currentQuizConditionSlug] = useLocalStorage(currentActiveQuizKey)
  const { employer } = useEmployerContext()
  const { navigationUrl, navigationText } = getQuizNavigationState(
    planId,
    planCondition,
    currentQuizConditionSlug,
    Condition.MENTAL_HEALTH,
    employer ? '' : 'Book a demo',
    employer
      ? `/quiz/${Condition.MENTAL_HEALTH}?authPage=${AuthPage.SIGNUP}&pc=mh-comp`
      : '/partner',
    user.isAuth,
    user.enrolled
  )
  return (
    <>
      <SEO
        title="Mental health treatment"
        description="Goodpath care for employee mental health, including stress, anxiety, depression."
        location={location}
      />

      {/* Hero */}

      <div className="relative items-center px-4 pt-6 sm:grid-cols-2 md:grid-cols-60-40 bg-gp-white sm:grid">
        <div className="z-10 sm:px-6 md:pl-12 lg:pl-16">
          <h1 className="text-3xl leading-tight md:text-5xl lg:text-6xl text-gp-violet">
            Complete, whole-person care for mental health
          </h1>
          <p className="mt-2 text-base leading-tight md:text-xl lg:text-2xl text-gp-grey-100">
            Integrative care for stress, anxiety, depression and more that
            factors in the rest of your life, plus a coach for every step
          </p>

          <div className="relative">
            <Link
              to={navigationUrl}
              id="mh-cta-start1"
              className="relative z-10 inline-flex px-10 py-4 mt-6 text-xl font-bold uppercase transition-all duration-300 rounded-xlg bg-gp-violet text-gp-off-white lg:text-2xl lg:px-7 lg:py-6 lg:min-h-24 hover:no-underline hover:bg-gp-violet-70 focus:no-underline focus:bg-gp-violet-70"
            >
              {navigationText}
            </Link>

            {/* Desktop Connection */}
            <div className="absolute z-0 flex-col items-center justify-center hidden ml-20 top-16 left-32 sm:flex">
              <Ring className="w-12 h-12 fill-current text-gp-honey" />
              <Line />
              <Circle className="w-12 h-12 fill-current text-gp-honey" />
            </div>
          </div>
        </div>

        <img
          src={MentalHeroMobile}
          alt="A woman that has gone through the Goodpath mental health program: her feelings of burnout are gone and her anxiety has dropped from moderate to a more normal level."
          className="mt-4 sm:hidden"
        />

        <img
          src={MentalHeroDesktop}
          alt="A woman that has gone through the Goodpath mental health program: her feelings of burnout are gone and her anxiety has dropped from moderate to a more normal level."
          className="z-10 hidden border-b-2 sm:block border-gp-violet"
        />
        <QuarterCircle className="absolute top-0 right-0 z-0 hidden sm:block sm:max-h-400 md:max-h-500 lg:max-h-700" />
      </div>

      {/* Mobile Connection */}

      <div className="flex flex-col items-center justify-center pt-4 pb-6 bg-gp-white sm:hidden">
        <Ring className="w-8 h-8 fill-current text-gp-honey" />
        <Line />
        <Circle className="w-8 h-8 fill-current text-gp-honey" />
      </div>

      {/* Section 2 */}
      <div className="md:mb-8">
        <h2 className={`${h2} sm:mt-6 md:mt-12 lg:mt-20`}>
          Our integrative approach addresses symptoms and contributing factors
        </h2>
        <img
          src={mentalHealthIntegrativeGifDesktop}
          alt="An animation showing the many parts of integrative care: exercise therapy, cognitive therapy, mind-body techniques, ergonomics, and nutrition counseling."
          className="hidden w-full max-w-2xl mx-auto md:block"
        />
        <img
          src={mentalHealthIntegrativeGifMobile}
          alt="An animation showing the many parts of integrative care: exercise therapy, cognitive therapy, mind-body techniques, ergonomics, and nutrition counseling."
          className="md:hidden"
        />
      </div>

      {/* Mobile Connection Start */}
      <div className="flex items-start w-full pl-16 pr-12 my-4 sm:hidden">
        <Ring className="w-8 h-8 -mt-4 fill-current text-gp-grapefruit" />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 256 102"
          className="flex-1"
        >
          <path
            stroke="#372C72"
            strokeWidth="2"
            d="M255 102V61c0-33-27-60-60-60H0"
          ></path>
        </svg>
      </div>

      {/* Desktop connection start */}
      <div className="justify-end hidden max-w-6xl pr-2 mx-auto md:flex">
        <div className="flex items-center justify-start w-1/2">
          <Ring className="w-12 h-12 -ml-4 fill-current text-gp-grapefruit" />
          <div className="flex-1 h-2 border-t-2 border-gp-violet"></div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="61"
            height="91"
            fill="none"
            className="transform rotate-180 mt-5/2"
          >
            <path
              stroke="#372C72"
              strokeWidth="2"
              d="M61 90C28 90 1 63 1 30V0"
            ></path>
          </svg>
        </div>
      </div>

      {/* Section 3 */}
      <h2 className={`${h2} -mt-4`}>
        Our care is personalized to wherever you are on your journey
      </h2>

      <div className="w-full px-4 mt-4">
        <img
          src={CareAnimationMobile}
          alt="An animation that represents a diverse group of people and the different solutions that Goodpath provides them: 1:1 coaching, 1:1 therapy, mind-body exercises, journaling, exercise programs."
          className="w-full sm:hidden"
        />
        <img
          src={CareAnimationDesktop}
          alt="An animation that represents a diverse group of people and the different solutions that Goodpath provides them: 1:1 coaching, 1:1 therapy, mind-body exercises, journaling, exercise programs."
          className="hidden w-full max-w-6xl mx-auto sm:block md:px-2"
        />
      </div>

      {/* Desktop connection end */}
      <div className="hidden max-w-6xl pl-2 mx-auto -mb-16 md:block">
        <div className="flex items-center justify-end w-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="61"
            height="91"
            fill="none"
            className="mb-24"
          >
            <path
              stroke="#372C72"
              strokeWidth="2"
              d="M61 90C28 90 1 63 1 30V0"
            ></path>
          </svg>
          <div className="flex-1 h-2 border-t-2 border-gp-violet"></div>
          <Circle className="w-12 h-12 -mr-4 fill-current text-gp-grapefruit" />
        </div>
      </div>

      {/* Connection End Mobile */}
      <div className="flex items-end w-full pl-16 pr-12 sm:hidden">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 256 102"
          className="flex-1"
        >
          <path
            stroke="#372C72"
            strokeWidth="2"
            d="M1 0v41c0 33 27 60 60 60h195"
          ></path>
        </svg>
        <Circle className="w-8 h-8 -mb-4 fill-current text-gp-grapefruit" />
      </div>

      {/* Section 4 */}
      <div className="mt-10 mb-2">
        <h2 className={h2}>
          With results that lead to less worry and better days
        </h2>
      </div>

      {/* Connection */}
      <div className="flex flex-col items-center justify-center pt-4 pb-6 bg-gp-white">
        <Ring className="w-8 h-8 fill-current md:w-12 md:h-12 text-gp-honey" />
        <div className="relative flex flex-col items-center justify-center w-full">
          <img src={squiggle} alt="" className="mr-3" />
          <div className="absolute bottom-0 z-10 grid grid-cols-2 gap-4 px-4 mb-8 md:gap-24">
            <div className="flex flex-col items-center justify-center max-w-200 md:max-w-300">
              <h3 className="text-lg leading-tight text-center md:text-2xl text-gp-grey-100 ">
                <span className="text-80 md:text-150">
                  <b className="font-bold">43</b>%
                </span>
                <br />
                average drop in <b className="font-bold">anxiety</b>
                <sup>1</sup>
              </h3>
            </div>
            <div className="text-lg leading-tight text-center text-gp-grey-100 max-w-200 md:max-w-300">
              <h3 className="text-lg leading-tight md:text-2xl text-gp-grey-100">
                <span className="text-80 md:text-150">
                  <b className="font-bold">63</b>%
                </span>
                <br />
                average drop in <b className="font-bold">depression</b>
                <sup>1</sup>
              </h3>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col items-center justify-center w-full mb-4">
          <Circle className="w-8 h-8 fill-current md:w-12 md:h-12 text-gp-honey" />
          <Link
            to={navigationUrl}
            id="mh-cta-start1"
            className="absolute bottom-0 z-10 inline-flex px-10 py-4 -mb-10 text-xl font-bold uppercase transition-all duration-300 md:-mb-14 rounded-xlg bg-gp-violet text-gp-off-white lg:text-2xl lg:px-7 lg:py-6 lg:min-h-24 hover:no-underline hover:bg-gp-violet-70 focus:no-underline focus:bg-gp-violet-70"
          >
            {navigationText}
          </Link>
        </div>
      </div>

      {/* Section 5 */}
      <h2 className={`${h2} mt-8 md:mt-24`}>Here's how it works</h2>

      {/* Mobile Connection */}
      <div className="flex flex-col items-center justify-center pt-4 pb-6 bg-gp-white sm:hidden">
        <Ring className="w-8 h-8 fill-current text-gp-violet" />
        <Line />
      </div>

      <div className="max-w-6xl mx-auto space-y-16 md:space-y-0 md:mt-16 md:px-6">
        <div className="relative items-start md:flex">
          <div className="relative flex flex-col md:border-b-2 md:border-r-2 md:pt-6 border-gp-violet md:rounded-br-xxlg md:mr-auto md:items-center md:min-w-550">
            <h3 className={`${h3} mb-4`}>
              Complete a short, holistic assessment
            </h3>
            <img
              src={assessmentScreen}
              alt="Screenshot of the Goodpath intake assessment."
              className="mx-auto border-b-2 max-w-200 border-gp-violet md:border-none"
            />
            <Ring className="absolute top-0 right-0 z-10 hidden w-8 h-8 -mt-12 -mr-6 fill-current md:w-12 md:h-12 text-gp-violet md:block" />
          </div>
        </div>

        <div className="grid-cols-2 space-y-16 md:border-b-2 md:border-l-2 md:space-y-0 md:grid border-gp-violet md:rounded-bl-xxlg md:pt-12 md:pb-0 gap-x-20 md:px-16">
          <div className="flex flex-col justify-end flex-1">
            <h3 className={`${h3} mb-4`}>
              We build your custom, whole-person program...
            </h3>
            <img
              src={appScreens}
              alt="Screenshots of the Goodpath mobile app."
              className="mx-auto border-b-2 max-w-300 border-gp-violet md:border-none"
            />
          </div>

          <div className="flex flex-col justify-end flex-1">
            <h3 className={`${h3} mb-4`}>
              ...ship any physical items straight to your door...
            </h3>
            <img
              src={products}
              alt="Goodpath box and sample supplements."
              className="mx-auto border-b-2 max-w-300 border-gp-violet md:hidden"
            />
            <img
              src={productsDesktop}
              alt="Goodpath box and sample supplements."
              className="hidden mx-auto max-w-300 md:border-none md:block"
            />
          </div>
        </div>

        <div className="flex flex-col md:border-b-2 md:border-r-2 md:pt-12 border-gp-violet rounded-br-xxlg md:ml-auto md:items-center md:max-w-1/2">
          <h3 className={`${h3} mb-4`}>
            ...and match you with a dedicated coach for every step
          </h3>
          <img
            src={coachScreen}
            alt="Screenshot of the Goodpath coaching screen on the Goodpath mobile app."
            className="mx-auto border-b-2 md:mx-0 max-w-200 border-gp-violet md:border-none"
          />
        </div>
      </div>

      {/* Connection */}
      <div className="flex flex-col items-center justify-center pt-4 pb-6 md:pt-0 bg-gp-white">
        <Line />
      </div>

      {/* Section 6 */}

      <h2 className={h2}>
        It's complete, scalable support that helps employers take better care of
        their teams
      </h2>

      <div className="max-w-6xl grid-cols-3 mx-auto md:grid gap-x-12 md:pb-12">
        <div className="flex flex-col items-center px-8 mt-8 text-center">
          <img
            src={batteryIcon}
            alt="Low battery symbol."
            className="max-h-80"
          />
          <h3 className="mt-2 text-4xl font-bold text-gp-grey-100">3 in 5</h3>
          <h4 className="text-lg leading-tight text-gp-grey-100">
            employees lose interest, motivation, and energy from burnout
            <sup>2</sup>
          </h4>
        </div>

        <div className="flex flex-col items-center px-8 mt-8 text-center">
          <img
            src={brainIcon}
            alt="A lightning bolt and a brain showing the connection between physical conditions and mental health conditions."
            className="max-h-80"
          />
          <h3 className="mt-2 text-4xl font-bold text-gp-grey-100">1 in 2</h3>
          <h4 className="text-lg leading-tight text-gp-grey-100">
            of Goodpath members with a chronic physical condition also have a
            mental health condition
            <sup>3</sup>
          </h4>
        </div>

        <div className="flex flex-col items-center px-8 mt-8 text-center">
          <img
            src={targetIcon}
            alt="Blurry target symbol."
            className="max-h-80"
          />
          <h3 className="mt-2 text-4xl font-bold text-gp-grey-100">61%</h3>
          <h4 className="text-lg leading-tight text-gp-grey-100">
            of employees have trouble focusing because of mental health strain
            <sup>4</sup>
          </h4>
        </div>
      </div>

      {/* Mobile Connection */}
      <div className="flex flex-col items-center justify-center pt-4 pb-2 bg-gp-white md:hidden">
        <Line />
      </div>

      {/* Desktop connection */}
      <div className="items-start justify-start hidden max-w-5xl mx-auto md:flex">
        <div className="min-w-300"></div>
        <div className="border-b-2 border-r-2 -ml-2px min-h-100 min-w-200 border-gp-violet rounded-br-xxlg"></div>
      </div>

      {/* Section 7 */}
      <div className="max-w-5xl pt-2 pb-8 mx-auto md:pt-0 md:pb-0 md:flex">
        <div className="md:min-w-300 md:border-r-2 border-gp-violet md:pr-4">
          <h2 className="px-4 pb-1 mx-auto text-3xl leading-tight tracking-tighter text-center sofia sm:text-4xl md:max-w-1/2 lg:max-w-4xl text-gp-grey-100 md:text-right md:pt-8 lg:pt-12">
            It's why these clients and partners joined Goodpath
          </h2>
        </div>

        <div className="px-8 mt-4 md:px-2 md:mt-0">
          <PartnerImages border={false} />
        </div>
      </div>

      {/* Desktop connection */}
      <div className="items-start justify-start hidden max-w-5xl mx-auto md:flex">
        <div className="min-w-300"></div>
        <div className="border-b-2 border-l-2 -ml-2px min-h-45 min-w-200 border-gp-violet rounded-bl-xxlg"></div>
      </div>

      <div className="items-start justify-start hidden max-w-5xl mx-auto mb-6 md:flex">
        <div className="min-w-500"></div>
        <div className="flex flex-col items-center justify-center -ml-25px -mt-2px">
          <Line />
          <Circle className="w-8 h-8 -mb-4 fill-current md:h-12 md:w-12 text-gp-violet" />
        </div>
      </div>

      {/* Mobile Connection */}
      <div className="flex flex-col items-center justify-center pb-6 mt-8 md:mt-0 bg-gp-white md:hidden">
        <Line />
        <Circle className="w-8 h-8 -mb-4 fill-current md:h-12 md:w-12 text-gp-violet" />
      </div>

      {/* Section 8 */}
      <div className="max-w-4xl px-4 mx-auto md:px-8 lg:px-16">
        <div className="px-8 py-12 text-center bg-gp-violet rounded-xxlg">
          <h2 className="flex flex-col items-center justify-center text-4xl leading-none text-center text-white md:text-5xl">
            Bring Goodpath mental health to your company
          </h2>
          <h3 className="mt-6 text-xl leading-tight text-center text-white md:text-2xl">
            Goodpath's integrative mental health care helps return employees to
            work faster.
          </h3>
          <Link
            to="/partner"
            className="inline-flex px-10 py-6 mt-6 text-xl font-bold uppercase transition-all duration-300 rounded-xlg bg-gp-honey text-gp-violet lg:text-2xl lg:px-7 lg:min-h-24 hover:no-underline hover:bg-gp-honey-70 focus:no-underline focus:bg-gp-honey-70"
          >
            Request a demo
          </Link>
        </div>
      </div>

      {/* Section 9 */}

      <h2 className={`${h2} mt-8 md:mt-32`}>Recent press and publications</h2>

      <div className="flex flex-col grid-cols-3 px-8 mx-auto mt-8 space-y-6 md:space-y-0 max-w-7xl md:grid md:gap-x-8 lg:gap-x-20">
        <Link
          target="_blank"
          to="https://www.benefitnews.com/news/goodpath-and-long-covid-recovery-through-integrative-care"
          className="flex flex-col"
        >
          <img
            src={ebnArticle}
            alt="EBN news article featuring a woman in a mask picking tomatoes from the grocery store."
          />
          <h3 className="mt-4 text-2xl font-bold leading-tight text-gp-grey-100">
            Integrative care may be the key to helping people with long COVID
          </h3>
          <p className="mt-2 text-lg leading-tight text-gp-grey-100">
            April 12, 2022
          </p>
        </Link>

        <Link
          target="_blank"
          to="https://time.com/6160010/long-covid-patients-back-to-work/"
          className="flex flex-col"
        >
          <img
            src={timeArticle}
            alt="Time news article of men and women walking through a security checkpoint in a office building."
          />
          <h3 className="mt-4 text-2xl font-bold leading-tight text-gp-grey-100">
            Back-to-Office Pressure Is Creating a Crisis for Long COVID Patients
          </h3>
          <p className="mt-2 text-lg leading-tight text-gp-grey-100">
            April 5, 2022
          </p>
        </Link>

        <Link
          target="_blank"
          to="https://www.bloomberg.com/press-releases/2022-03-10/yamaha-motor-adds-goodpath-wellness-benefit-to-underscore-its-persistence-innovation-and-vision-in-employee-management"
          className="flex flex-col"
        >
          <img
            src={bloombergArticle}
            alt="Bloomberg new article of someone riding a Yamaha motorcycle."
          />
          <h3 className="mt-4 text-2xl font-bold text-gp-grey-100">
            Yamaha Motor adds Goodpath Wellness leading-tightBenefit to
            Underscore its Persistence, Innovation, and Vision in Employee
            Management
          </h3>
          <p className="mt-2 text-lg leading-tight text-gp-grey-100">
            March 10, 2022
          </p>
        </Link>
      </div>

      {/* Section 10 */}
      <div className="flex flex-col px-6 mx-auto mb-8 mt-28 max-w-7xl">
        <h2 className="text-sm text-gp-grey-70">References</h2>
        <ol className="w-full max-w-4xl mt-1 space-y-1 text-sm leading-normal list-decimal text-gp-grey-70">
          <li>
            Results from Q1 &amp; Q2 Goodpath Cohorts; Outcomes are consistent
            across employer types
          </li>
          <li>
            American Psychological Association 2021 Work and Well-being Survey
          </li>
          <li>
            Results from Q1 2022 cohort of Goodpath members at a client with
            mixed workplace environments
          </li>
          <li>The Hartford's 2021 Future of Benefits Study Report</li>
        </ol>
      </div>
    </>
  )
}

export default MentalHealth
